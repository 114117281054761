<template>
  <div class="hospital">
    <vaeSearch @vaeSearch="vaeSearch" placeholder="搜索医院名称"></vaeSearch>
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :immediate-check="false"
        @load="productLoad"
      >
        <vaeHospital
          @hospitalDetail="clickHospitalList"
          :hosList="hotHospitalList"
        ></vaeHospital>
      </van-list>
    </div>
  </div>
</template>

<script>
import vaeHospital from '@/components/hospitalList.vue'
import { hospitalList, cateList } from '@/api/studentProduct'
export default {
  components: {
    vaeHospital
  },
  data () {
    return {
      value: '',
      hotHospitalList: [],
      search_ward: '',
      is_show: true,
      loading: false,
      finished: false,
      countAll: null,
      listQuery: {
        page: 1, // 页数
        limit: 10
      },
      listObj: {
        type: 2,
        page: 1,
        search_word: ''
      }
    }
  },
  created () {
    const type = this.$route.query.type
    this.getHopital(type)
  },
  watch: {
    search_ward: {
      handler (val) {
        if (val == '') {
          this.getHopital(this.$route.query.type)
        }
      },
      deep: true
    }
  },
  methods: {
    async getHopital (type) {
      const res = await hospitalList({ type: type })
      if (res.data.code == 200) {
        if(this.hotHospitalList) {
          this.hotHospitalList = []
        }
        res.data.data.list.forEach(element => {
          this.hotHospitalList.push(element)
        })
        if (this.hotHospitalList.length >= res.data.data.count) {
          this.finished = true
        }
        this.countAll = res.data.data.count
      }
    },
    async vaeSearch (val) {
      const res = await hospitalList({
        search_word: val,
        type: this.$route.query.type,
        page: 1
      })
      if (res.data.code === 200) {
        this.hotHospitalList = res.data.data.list
      }
    },
    // 分页
    productLoad () {
      console.log(this.listObj.page,Math.ceil(this.countAll/10) );
       if(this.listObj.page >= Math.ceil(this.countAll/10) ) {
         this.loading =  false
       }
       this.loading =  true
       setTimeout(() => {
         this.listObj.page++;
        this.getHopital(this.listObj)
        this.loading =  false
       }, 1000);
    },
    clickHospitalList (val) {
      setTimeout(() => {
        this.$router.push({
          path: '/hospitalInfo',
          query: {
            id: val.id
          }
        })
      }, 500)
    }
  }
}
</script>

<style lang="less" scoped>
.hospital {
  background: #f7f8f9;
  height: calc(100vh - 145px);
}
</style>
