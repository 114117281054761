<template>
  <div class="hospitalList">
    <div
      class="item"
      v-for="(itemone, indexone) in hosList"
      :key="indexone"
      @click="hospitalDetail(itemone)"
    >
      <span class="header"><img :src="itemone.img" class="coverImg" alt="" align="center" /></span>
      <div class="itemInfo flex2">
        <div class="itmeCenter">
          <div class="title ">{{ itemone.title }}</div>
          <div class="adress flex2">
            <img
              src="@/assets/hospital/position.svg"
              class="positionImg"
              alt=""
            />
            <span class="text one">{{ itemone.address }}</span>
          </div>
          <div class="label one">
            <span class="seletet" v-for="item in itemone.tag" :key="item.id">{{
              item.name
            }}</span>
          </div>
        </div>
        <div class="distance flexClumn">
          <!-- <span>2.5km</span> -->
          <span class="flex2" style="margin-top:12px;"> <span class="flex2" ><van-icon name="arrow" color="#333333" size="14"/></span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hosList: {
      type: Array
    }
  },
  methods: {
    hospitalDetail (val) {
      this.$emit('hospitalDetail', val)
    }
  }
}
</script>

<style lang="less" scoped>
.hospitalList {
  padding: 15px;
  .item {
    display: flex;
    background-color: #fff;
    padding: 10px;
    box-shadow: 1px 1px 10px rgba(0, 96, 240, 0.1);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 10px;
    .header {
      .coverImg {
        border-radius: 10px;
        width: 80px;
        height: 80px;
      }
    }
    
    .itemInfo {
      padding-left: 10px;

      color: #333333;
      .itmeCenter {
        .adress {
          margin-top: -5px;
          margin-bottom: 5px;
          font-size: 14px;
          height: 40px;
          line-height: 40px;
          .positionImg {
            padding-top: 10px;
            width: 14px;
            height: 14px;
          }
          .text {
            padding-left: 5px;
          }
        }
        .label {
          font-size: 10px;
          .seletet {
            display: inline-block;
            text-align: justify;
            text-justify: newspaper;
            word-break: break-all;
            padding: 0 13px;
            background: #cddbf6;
            border-radius: 15px;
            margin-right: 10px;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
      }
    }
    .distance {
      padding-left: 10px;
      font-size: 14px;
    }
  }
  .title {
    font-weight: bold;
    font-size: 16px;
  }
  .flex2 {
    display: flex;
    align-items: center;
  }
  .flexClumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
